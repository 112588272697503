/// <reference types="vite/client" />

import "@/assets/styles/frontend/global.css"
import * as Turbo from "@hotwired/turbo"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

Turbo.session.drive = true

const application = Application.start()
const controllers = import.meta.glob("../controllers/**/*_controller.ts", {
  eager: true
})
registerControllers(application, controllers)
